import Disclosure from '@/components/ui/Disclosure';
import { useCurrentCategoryApiSyntax } from '@/lib/hooks/useCurrentCategoryApiSyntax';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import useTranslation from 'next-translate/useTranslation';
import { ChangeEventHandler, ReactNode } from 'react';
import {
  setMaxClampingForce,
  setMaxForceSheetMetal,
  setMaxLaserSheetMetal,
  setMaxMaximumPayload,
  setMaxMaximumReach,
  setMaxScrewDiameter,
  setMaxTableSize,
  setMaxThicknessSheetMetal,
  setMaxXAxisMovement,
  setMaxXaxisSheetmetal,
  setMinClampingForce,
  setMinForceSheetMetal,
  setMinLaserSheetMetal,
  setMinMaximumPayload,
  setMinMaximumReach,
  setMinScrewDiameter,
  setMinTableSize,
  setMinThicknessSheetMetal,
  setMinXAxisMovement,
  setMinXaxisSheetmetal,
} from './filterSlice';

export function TechnicalFilters() {
  const categoryApiName = useCurrentCategoryApiSyntax();
  if (!categoryApiName) {
    return null;
  }
  if (categoryApiName === 'machine-tool') {
    return (
      <DisclosureTechnical>
        <MachineToolsFilters />
      </DisclosureTechnical>
    );
  }
  if (categoryApiName === 'sheet-metal') {
    return (
      <DisclosureTechnical>
        <SheetMetalFilters />
      </DisclosureTechnical>
    );
  }
  if (categoryApiName === 'plastics-processing') {
    return (
      <DisclosureTechnical>
        <PlasticProcessingFilters />
      </DisclosureTechnical>
    );
  }
  if (categoryApiName == 'automation-equipment') {
    return (
      <DisclosureTechnical>
        <AutomationEquipmentFilters />
      </DisclosureTechnical>
    );
  }
  console.warn('Not expecting category as:', categoryApiName);
  return null;
}

function DisclosureTechnical({ children }: { children: ReactNode }) {
  const { t } = useTranslation();
  return (
    <Disclosure
      title={t('products:PRODUCT_LIST_TECHNICAL_FILTERS')}
      isOpenDefaultState={false}
      dataTestId='technical-filters'
    >
      {children}
    </Disclosure>
  );
}

function MachineToolsFilters() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const productFilter = useAppSelector((state) => state.productFilter);

  return (
    <div className='space-y-6'>
      <TechnicalFilter
        title={t('products:PRODUCT_LIST_FILTERS_MOVEMENT')}
        minCurrentValue={productFilter.min_xaxis_movement}
        maxCurrentValue={productFilter.max_xaxis_movement}
        eventHandlerMin={(e) =>
          dispatch(setMinXAxisMovement(parseInt(e.target.value) || undefined))
        }
        eventHandlerMax={(e) =>
          dispatch(setMaxXAxisMovement(parseInt(e.target.value) || undefined))
        }
        dataTestId='movement'
      />
      <TechnicalFilter
        title={t('products:PRODUCT_LIST_FILTERS_TABLE_SIZE')}
        minCurrentValue={productFilter.min_tablesize}
        maxCurrentValue={productFilter.max_tablesize}
        eventHandlerMin={(e) =>
          dispatch(setMinTableSize(parseInt(e.target.value) || undefined))
        }
        eventHandlerMax={(e) =>
          dispatch(setMaxTableSize(parseInt(e.target.value) || undefined))
        }
        dataTestId='table-size'
      />
    </div>
  );
}

function SheetMetalFilters() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const productFilter = useAppSelector((state) => state.productFilter);

  return (
    <div className='space-y-6'>
      <TechnicalFilter
        title={t('products:PRODUCT_LIST_FILTERS_MOVEMENT')}
        minCurrentValue={productFilter.min_xaxis_sheetmetal}
        maxCurrentValue={productFilter.max_xaxis_sheetmetal}
        eventHandlerMin={(e) =>
          dispatch(setMinXaxisSheetmetal(parseInt(e.target.value) || undefined))
        }
        eventHandlerMax={(e) =>
          dispatch(setMaxXaxisSheetmetal(parseInt(e.target.value) || undefined))
        }
        dataTestId='movement-sheetmetal'
      />
      <TechnicalFilter
        title={t('products:PRODUCT_LIST_FILTERS_FORCE')}
        minCurrentValue={productFilter.min_force_sheetmetal}
        maxCurrentValue={productFilter.max_force_sheetmetal}
        eventHandlerMin={(e) =>
          dispatch(setMinForceSheetMetal(parseInt(e.target.value) || undefined))
        }
        eventHandlerMax={(e) =>
          dispatch(setMaxForceSheetMetal(parseInt(e.target.value) || undefined))
        }
        dataTestId='force-sheetmetal'
      />
      <TechnicalFilter
        title={t('products:PRODUCT_LIST_FILTERS_MAXIMUM_SHEET_THICKNESS')}
        minCurrentValue={productFilter.min_thickness_sheetmetal}
        maxCurrentValue={productFilter.max_thickness_sheetmetal}
        eventHandlerMin={(e) =>
          dispatch(
            setMinThicknessSheetMetal(parseInt(e.target.value) || undefined)
          )
        }
        eventHandlerMax={(e) =>
          dispatch(
            setMaxThicknessSheetMetal(parseInt(e.target.value) || undefined)
          )
        }
        dataTestId='maximum-sheet-thickness'
      />
      <TechnicalFilter
        title={t('products:PRODUCT_LIST_FILTERS_LASER_POWER')}
        minCurrentValue={productFilter.min_laser_sheetmetal}
        maxCurrentValue={productFilter.max_laser_sheetmetal}
        eventHandlerMin={(e) =>
          dispatch(setMinLaserSheetMetal(parseInt(e.target.value) || undefined))
        }
        eventHandlerMax={(e) =>
          dispatch(setMaxLaserSheetMetal(parseInt(e.target.value) || undefined))
        }
        dataTestId='laser-power'
      />
    </div>
  );
}

function PlasticProcessingFilters() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const productFilter = useAppSelector((state) => state.productFilter);

  return (
    <div className='space-y-6'>
      <TechnicalFilter
        title={t('products:PRODUCT_LIST_FILTERS_CLAMPING_FORCE')}
        minCurrentValue={productFilter.min_clamping_force}
        maxCurrentValue={productFilter.max_clamping_force}
        eventHandlerMin={(e) =>
          dispatch(setMinClampingForce(parseInt(e.target.value) || undefined))
        }
        eventHandlerMax={(e) =>
          dispatch(setMaxClampingForce(parseInt(e.target.value) || undefined))
        }
        dataTestId='clamping-force'
      />
      <TechnicalFilter
        title={t('products:PRODUCT_LIST_FILTERS_SCREW_DIAMETER')}
        minCurrentValue={productFilter.min_screw_diameter}
        maxCurrentValue={productFilter.max_screw_diameter}
        eventHandlerMin={(e) =>
          dispatch(setMinScrewDiameter(parseInt(e.target.value) || undefined))
        }
        eventHandlerMax={(e) =>
          dispatch(setMaxScrewDiameter(parseInt(e.target.value) || undefined))
        }
        dataTestId='screw-diameter'
      />
    </div>
  );
}

function AutomationEquipmentFilters() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const productFilter = useAppSelector((state) => state.productFilter);

  return (
    <div className='space-y-6'>
      <TechnicalFilter
        title={t('products:PRODUCT_LIST_FILTERS_MAXIMUM_REACH')}
        minCurrentValue={productFilter.min_maximum_reach}
        maxCurrentValue={productFilter.max_maximum_reach}
        eventHandlerMin={(e) =>
          dispatch(setMinMaximumReach(parseInt(e.target.value) || undefined))
        }
        eventHandlerMax={(e) =>
          dispatch(setMaxMaximumReach(parseInt(e.target.value) || undefined))
        }
        dataTestId='maximum-reach'
      />
      <TechnicalFilter
        title={t('products:PRODUCT_LIST_FILTERS_MAXIMUM_PAYLOAD')}
        minCurrentValue={productFilter.min_maximum_payload}
        maxCurrentValue={productFilter.max_maximum_payload}
        eventHandlerMin={(e) =>
          dispatch(setMinMaximumPayload(parseInt(e.target.value) || undefined))
        }
        eventHandlerMax={(e) =>
          dispatch(setMaxMaximumPayload(parseInt(e.target.value) || undefined))
        }
        dataTestId='maximum-payload'
      />
    </div>
  );
}

function TechnicalFilter({
  title,
  minCurrentValue,
  maxCurrentValue,
  eventHandlerMin,
  eventHandlerMax,
  dataTestId,
}: {
  title: string;
  minCurrentValue?: number;
  maxCurrentValue?: number;
  eventHandlerMin: ChangeEventHandler<HTMLInputElement>;
  eventHandlerMax: ChangeEventHandler<HTMLInputElement>;
  dataTestId?: string;
}) {
  const { t } = useTranslation();
  return (
    <div>
      <h4 className='mb-4'>{title}</h4>
      <div>
        <form>
          <ul className='space-y-2' data-testid={dataTestId}>
            <InputTechnical
              label={t('products:PRODUCT_LIST_FILTERS_FROM')}
              name='from'
              id='range-start'
              eventHandler={eventHandlerMin}
              value={minCurrentValue}
            />
            <InputTechnical
              label={t('products:PRODUCT_LIST_FILTERS_TO')}
              name='to'
              id='range-end'
              eventHandler={eventHandlerMax}
              value={maxCurrentValue}
            />
          </ul>
        </form>
      </div>
    </div>
  );
}

function InputTechnical({
  label,
  name,
  id,
  placeholder,
  eventHandler,
  value,
}: {
  label: string;
  name: string;
  id: string;
  placeholder?: string;
  eventHandler: ChangeEventHandler<HTMLInputElement>;
  value?: number;
}) {
  return (
    <li>
      <div className='flex items-center justify-between space-x-2'>
        <label className='text-brand-500'>{label}:</label>
        <input
          type='number'
          name={name}
          id={id}
          placeholder={placeholder}
          onChange={eventHandler}
          value={value}
        />
      </div>
    </li>
  );
}
