import useTranslation from 'next-translate/useTranslation';

export type Languages =
  | 'en'
  | 'pt-PT'
  | 'fr-FR'
  | 'cs-CZ'
  | 'tr-TR'
  | 'pl-PL'
  | 'fi-FI'
  | 'hu-HU'
  | 'hr-HR'
  | 'lv-LV'
  | 'lt-LT'
  | 'en-GB'
  | 'et-EE'
  | 'en-IN'
  | 'es-MX'
  | 'de-AT'
  | 'en-IE'
  | 'nl-NL'
  | 'en-US'
  | 'de-DE'
  | 'it-IT'
  | 'ru-RU'
  | 'es-ES'
  | 'sv-SE'
  | 'en-AE'
  | 'uk-UA';

export function useTranslationFuz() {
  const { t } = useTranslation();
  function tFuzz(key: string) {
    let name = t('products:' + key);
    name = name.includes('products:') ? t('products:' + key) : name;
    name = name.includes('products:') ? key : name;
    return name ?? '';
  }
  return { tFuzz };
}
