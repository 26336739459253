import {
  ApiProductsResponse,
  ApiProductResponse,
  ProductItem,
} from '@/lib/types/api';
import { isNil, omitBy } from 'lodash-es';
import {
  IOrderby,
  ProductFilterState,
} from '@/components/forms/Filters/filterSlice';
import { DEFAULT_PAGE_SIZE } from '@/lib/repository/constants';
import { getApiClient } from '../apiClient';

function getClient(locale: string) {
  const client = getApiClient(locale);
  return client;
}

export const getProductsByForm = async (
  filters: ProductFilterState = { page: 1 },
  locale: string
): Promise<ApiProductsResponse> => {
  const {
    brand,
    model,
    application_type,
    search,
    machine_category,
    machine_type,
    localization_country,
    order_by,
    ...restFilters
  } = filters;
  const query: ProductsSearchQuery = {
    ...restFilters,
    application_type: application_type?.map((item) => item.value),
    brand: brand?.map((item) => item.value),
    model: model?.map((item) => item.value),
    machine_type: machine_type?.map((item) => item.value),
    localization_country: localization_country?.map((item) => item.value),
    store: 'COM',
    page_size: DEFAULT_PAGE_SIZE,
    statuses: [1, 2, 3, 4, 7, 8, 9, 12, 13],
    order_by: order_by ?? 'recent_added_first',
    search_text: search,
  };

  if (machine_category) {
    query.machine_category = machine_category;
  }

  const payload = omitBy(query, isNil);

  const { data } = await getClient(locale).post<ApiProductsResponse>(
    '/api/products',
    payload
  );
  return data;
};

export const getProducts = async (
  params: ProductsSearchQuery & { locale: string }
): Promise<ApiProductsResponse> => {
  const {
    page_size = DEFAULT_PAGE_SIZE,
    store = 'COM',
    order_by = 'recent_added_first',
    machine_category,
    statuses = [1, 2, 3, 4, 7, 8, 9, 12, 13],
    brand,
    locale,
  } = params;

  const isEmptyFilters = !brand;
  const payload = omitBy(
    {
      page_size,
      store,
      order_by,
      machine_category,
      statuses,
      brand,
    },
    (value) => value === undefined || value === null || value === ''
  );

  try {
    const { data } = await getClient(locale).post<ApiProductsResponse>(
      '/api/products',
      payload
    );
    if (isEmptyFilters && data.data.total_results === 0) {
      throw new Error('No products found for getProducts without filters');
    }
    return data;
  } catch (error) {
    console.error('Error fetching products:', error);
    console.error('Payload:', payload);
    throw new Error('Error fetching products');
  }
};

export interface ProductsSearchQuery {
  page_size?: number;
  store?: 'COM';
  country?: 'US';
  order_by?: IOrderby;
  machine_category?: string;
  machine_type?: string[];
  brand?: string[];
  model?: string[];
  localization_country?: string[];
  application_type?: string[];
  control_unit_brand?: string[];
  year_range?: string;
  min_price?: number;
  max_price?: number;
  statuses?: number[];
  search_text?: string;
  user_ip?: string;
  [key: string]: any; // Allow additional properties
}

export const getProductDetails = async (
  sku: string,
  locale = 'en-US'
): Promise<ProductItem | null> => {
  const apiUrl = `/api/product/${encodeURIComponent(sku)}`;

  try {
    const { data } = await getClient(locale).get<ApiProductResponse>(apiUrl);
    if (data.status === 'error') {
      console.warn('getProductDetails: status', data.status);
      throw new Error(`API error: ${data.status}`);
    }
    return data.data as ProductItem;
  } catch (err: any) {
    if (err.response && err.response.status === 404) {
      console.warn(
        'getProductDetails: status 404',
        locale,
        encodeURIComponent(sku)
      );
      return null;
    }
    throw err;
  }
};

interface QueryI {
  brand?: string[];
  application_type?: string[];
  machine_type?: string[];
}

export const getRelatedProductsByForm = async (
  filters: ProductFilterState = { page: 1 },
  locale: string
): Promise<ApiProductsResponse> => {
  const query: QueryI = {};

  if (filters.brand?.[0]?.value) {
    query.brand = [filters.brand[0].value];
  }
  if (filters.application_type?.[0]?.value) {
    query.application_type = filters.application_type.map((item) => item.value);
  }
  if (filters.machine_type?.[0]?.value) {
    query.machine_type = filters.machine_type.map((item) => item.value);
  }

  const { data } = await getClient(locale).post<ApiProductsResponse>(
    `/api/related-products`,
    query
  );
  return data;
};
