import axios, { AxiosInstance } from 'axios';
import { getApiFromLocal } from '../data-transformer/getApiDomainFromLocale';

export function getApiClient(locale: string): AxiosInstance {
  const baseURL = getApiFromLocal(locale);

  const apiClient = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return apiClient;
}
