import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { last } from 'lodash-es';
import { range } from 'lodash-es';
import useProducts from '@/lib/hooks/useProducts';
import { AppState } from '@/redux/store';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { setPage } from './filterSlice';
import { DEFAULT_PAGE_SIZE } from '@/lib/repository/constants';

export function Pagination() {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector(
    (state: AppState) => state.productFilter.page
  );
  const { apiProductsResponse } = useProducts();
  const LAST_PAGE = Math.ceil(
    (apiProductsResponse?.data.total_results ?? 0) / DEFAULT_PAGE_SIZE
  );
  const pageOptions = range(1, LAST_PAGE + 1);
  let lastButtonIsThreeDots = false;
  // currentPage is always 1 on server side, this is a way of making sure that it will only render on client side
  const [displayCurrentPage, setDisplayCurrentPage] = useState(0);
  const [pagesToShow, setPagesToShow] = useState(2);

  useEffect(() => {
    setDisplayCurrentPage(currentPage);
    if (window) {
      setPagesToShow(window.innerWidth < 768 ? 2 : 4);
    }
  }, [currentPage]);

  function handlePageChange(page: number) {
    try {
      window.scrollTo({ top: 0 });
      console.log('Scrolling up');
    } catch (error) {
      console.error(error);
    }
    dispatch(setPage(page));
  }

  return displayCurrentPage != 0 && pageOptions && pageOptions.length > 1 ? (
    <div
      className='btn-group mt-4 flex flex-nowrap justify-center'
      data-testid='filter-pagination'
    >
      {displayCurrentPage !== 1 && (
        <button
          className='btn'
          onClick={() => {
            handlePageChange(displayCurrentPage - 1);
          }}
        >
          «
        </button>
      )}
      {pageOptions.map((pageOption) => {
        const diff = pageOption - currentPage;
        if (
          pageOption == 1 ||
          Math.abs(diff) < pagesToShow ||
          pageOption === LAST_PAGE
        ) {
          lastButtonIsThreeDots = false;
          return (
            <button
              className={classNames({
                btn: true,
                'bg-brand-500 text-white': displayCurrentPage == pageOption,
              })}
              key={pageOption}
              onClick={() => {
                handlePageChange(pageOption);
              }}
              data-testid={'filter-page-' + pageOption}
            >
              {pageOption}
            </button>
          );
        } else {
          if (!lastButtonIsThreeDots) {
            lastButtonIsThreeDots = true;
            return (
              lastButtonIsThreeDots && (
                <button
                  className={classNames({
                    btn: true,
                    'bg-brand-500 text-white': currentPage == pageOption,
                  })}
                  key={pageOption}
                  disabled={true}
                >
                  ...
                </button>
              )
            );
          }
        }
      })}
      {currentPage !== last(pageOptions) && (
        <button
          className='btn'
          onClick={() => {
            handlePageChange(displayCurrentPage + 1);
          }}
        >
          »
        </button>
      )}
    </div>
  ) : null;
}
