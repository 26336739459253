import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(typeof window !== 'undefined' && windowSize.width <= 768);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize]);

  return isMobile;
}

export default useIsMobile;
