import { useRouter } from 'next-translate-routes-multi-domain';
/**
 * A custom React hook that extracts the current category API syntax from the URL pathname.
 *
 * @returns {string} The API syntax representation of the current category based on the URL pathname (wood-working, automation-equipment, etc...)
 */
export function useCurrentCategoryApiSyntax() {
  const { pathname } = useRouter();
  const pathNameParts = pathname.split('/');
  // Check if the current page is a 'products' page ( ex of product category page is /product/automation-equipment)
  if (pathNameParts[1] !== 'products') {
    console.warn(
      'useCurrentCategoryApiSyntax: pathname is not a products page'
    );
    return '';
  }
  const urlCategory = pathNameParts[2] ?? '';
  // remove 'used-' and '-machinery' from the urlCategory using regex
  const categoryApiName = urlCategory
    .replace(/used-|-machinery/g, '')
    .replace('machine-tools', 'machine-tool') as
    | 'plastics-processing'
    | 'machine-tool'
    | 'sheet-metal'
    | 'automation-equipment'
    | 'wood-working';
  return categoryApiName;
}
