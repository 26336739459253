import classNames from 'classnames';
import { useRouter } from 'next-translate-routes-multi-domain';
import Link from 'next-translate-routes-multi-domain/link';
import { useState } from 'react';
import { FaChevronUp } from 'react-icons/fa';

export default function Disclosure({
  title = '',
  isOpenDefaultState = false,
  options = [],
  isAlwaysOpen,
  links,
  children,
  onOptionChange,
  isOptionActive,
  dataTestId,
}: {
  title: string;
  isOpenDefaultState?: boolean;
  options?: { label: string; value: string }[];
  links?: {
    label: string;
    href: string;
  }[];
  isAlwaysOpen?: boolean;
  children?: React.ReactNode;
  onOptionChange?: (value: any) => void;
  isOptionActive?: (option: { label: string; value: string }) => boolean;
  dataTestId?: string;
}) {
  const [isOpen, setIsOpen] = useState(isAlwaysOpen || isOpenDefaultState);
  const { pathname } = useRouter();
  return (
    <div
      className='border-[1px] border-slate-200 p-4'
      data-testid={dataTestId ?? title}
    >
      <div
        className='flex cursor-pointer items-center justify-between'
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        <h5 className={'uppercase text-brand-500'}>{title}</h5>
        {!isAlwaysOpen && (
          <FaChevronUp
            className={classNames({
              'rotate-180 transform duration-100': isOpen,
              ' text-brand-500': true,
            })}
          />
        )}
      </div>
      <div
        className={classNames({
          'mt-4': true,
          hidden: !isOpen,
        })}
      >
        {options.map((option) => (
          <button
            className={classNames(
              'flex w-full cursor-pointer items-center justify-between px-4 py-2',
              isOptionActive && isOptionActive(option)
                ? '!hover:text-gray-600 bg-brand-500 text-white'
                : 'text-gray-600 hover:bg-slate-100'
            )}
            key={option.value}
            onClick={() => onOptionChange && onOptionChange(option)}
          >
            <p className='text-sm font-light uppercase'>{option.label}</p>
          </button>
        ))}
        {links &&
          links.map((link) => (
            <Link
              href={pathname === link.href ? '/products' : link.href}
              key={link.href}
            >
              <a
                className={classNames('text-sm font-light uppercase', {
                  ' text-gray-600': pathname !== link.href,
                  '  text-white': pathname === link.href,
                })}
              >
                <div
                  className={classNames(
                    'flex cursor-pointer items-center justify-between px-4 py-2',
                    {
                      'hover:bg-slate-100': pathname !== link.href,
                      'bg-brand-500': pathname === link.href,
                    }
                  )}
                >
                  {link.label}
                </div>
              </a>
            </Link>
          ))}
        {children}
      </div>
    </div>
  );
}
