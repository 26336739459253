import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import WantedRequestModal from './WantedRequestModal';
import { pushDataLayer } from '@/lib/utilities/analytics/gtm';

function WantedRequest() {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className='col-span-full h-64 w-full bg-gray-100 md:h-48'>
      <ul className='flex h-full flex-col items-center justify-center space-y-4 md:flex-row md:space-x-6 md:space-y-0'>
        <li>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src='/svg/magnifying-glass.svg' alt='' className='h-20' />
        </li>
        <li className='px-2 text-center font-secondary text-2xl font-extralight text-brand-500 md:text-left'>
          <div>
            {t('products:REQUEST_PRODUCT_LIST_HEADER')}
            <span className='font-medium'>
              {' '}
              {t('products:REQUEST_PRODUCT_LIST_HIGHLIGHT')}
            </span>
          </div>
          <div className='font-primary text-base'>
            {t('products:REQUEST_PRODUCT_LIST_P1')}
          </div>
        </li>
        <li className='last-item'>
          <button
            className='bg-brand-500 px-4 py-2 font-light text-white'
            onClick={() => {
              setIsModalOpen(true);
              pushDataLayer('buttonClick', {
                label: 'Send wanted request',
              });
            }}
            data-testid='open-wanted-request'
          >
            {t('products:REQUEST_BANNER_BTN')}
          </button>
          {isModalOpen && (
            <WantedRequestModal
              isOpen={isModalOpen}
              setIsOpen={setIsModalOpen}
            />
          )}
        </li>
      </ul>
    </div>
  );
}

export default WantedRequest;
