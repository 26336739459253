import { useAppSelector } from '@/redux/hooks';
import {
  ValueAndLabel,
  ValueLabelSlug,
} from '@/components/ui/Forms/Autocomplete/Autocomplete';
import { useCurrentCategoryApiSyntax } from './useCurrentCategoryApiSyntax';
import { useRouter } from 'next-translate-routes-multi-domain';

import { useQuery } from '@tanstack/react-query';
import { assignWith, isArray, isEmpty } from 'lodash-es';
import {
  getProductsByForm,
  getRelatedProductsByForm,
} from '@/lib/repository/products/products';

const useProducts = () => {
  const allFilters = useAppSelector((state) => state.productFilter);
  const {
    brand: brands,
    model: models,
    localization_continent,
    localization_country,
    application_type,
    machine_type,
    year_range,
    control_unit_brand,
    min_price,
    max_price,
    search,
    order_by,
    page,
    min_xaxis_movement,
    max_xaxis_movement,
    min_tablesize,
    max_tablesize,
    min_xaxis_sheetmetal,
    max_xaxis_sheetmetal,
    min_force_sheetmetal,
    max_force_sheetmetal,
    min_thickness_sheetmetal,
    max_thickness_sheetmetal,
    min_laser_sheetmetal,
    max_laser_sheetmetal,
    min_clamping_force,
    max_clamping_force,
    min_screw_diameter,
    max_screw_diameter,
    min_maximum_reach,
    max_maximum_reach,
    min_maximum_payload,
    max_maximum_payload,
  } = allFilters;
  const categoryApiName = useCurrentCategoryApiSyntax();
  const { locale: local } = useRouter();
  const locale = local ?? 'en-US';
  const cacheKey = getUseProductsCacheKey({
    categoryApiName,
    brands,
    models,
    localization_continent,
    localization_country,
    application_type,
    machine_type,
    year_range,
    control_unit_brand,
    min_price,
    max_price,
    search,
    order_by,
    page,
    locale,
    min_xaxis_movement,
    max_xaxis_movement,
    min_tablesize,
    max_tablesize,
    min_xaxis_sheetmetal,
    max_xaxis_sheetmetal,
    min_force_sheetmetal,
    max_force_sheetmetal,
    min_thickness_sheetmetal,
    max_thickness_sheetmetal,
    min_laser_sheetmetal,
    max_laser_sheetmetal,
    min_clamping_force,
    max_clamping_force,
    min_screw_diameter,
    max_screw_diameter,
    min_maximum_reach,
    max_maximum_reach,
    min_maximum_payload,
    max_maximum_payload,
  });

  if (process.env.LOGGING) {
    console.log('cacheKey', cacheKey);
  }

  const { data, isLoading } = useQuery(
    cacheKey,
    async () => {
      const products = await getProductsByForm(
        {
          brand: brands,
          page: page,
          machine_category: categoryApiName,
          model: models,
          localization_continent,
          localization_country,
          application_type,
          machine_type,
          year_range,
          control_unit_brand,
          min_price,
          max_price,
          search,
          order_by,
          min_xaxis_movement,
          max_xaxis_movement,
          min_tablesize,
          max_tablesize,
          min_xaxis_sheetmetal,
          max_xaxis_sheetmetal,
          min_force_sheetmetal,
          max_force_sheetmetal,
          min_thickness_sheetmetal,
          max_thickness_sheetmetal,
          min_laser_sheetmetal,
          max_laser_sheetmetal,
          min_clamping_force,
          max_clamping_force,
          min_screw_diameter,
          max_screw_diameter,
          min_maximum_reach,
          max_maximum_reach,
          min_maximum_payload,
          max_maximum_payload,
        },
        locale
      );
      if (
        products.status === 'error' ||
        isEmpty(products.data.products) ||
        (isArray(products.data.products) && products.data.products.length < 5)
      ) {
        const relatedProducts = await getRelatedProductsByForm(
          {
            brand: brands,
            model: models,
            application_type: application_type,
            page: 1,
          },
          locale
        );

        return {
          products: products,
          relatedProducts:
            relatedProducts.status === 'success' ? relatedProducts : undefined,
        };
      }
      return {
        products: products,
        relatedProducts: undefined,
      };
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: 0,
      refetchIntervalInBackground: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
  const apiProductsResponse = data?.products;
  const apiRelatedProductsResponse = data?.relatedProducts;

  return { apiProductsResponse, isLoading, apiRelatedProductsResponse };
};

export default useProducts;

export function getUseProductsCacheKey({
  categoryApiName,
  brands,
  models,
  localization_continent,
  localization_country,
  application_type,
  machine_type,
  year_range,
  control_unit_brand,
  min_price,
  max_price,
  search,
  order_by,
  page,
  locale,
  min_xaxis_movement,
  max_xaxis_movement,
  min_tablesize,
  max_tablesize,
  min_xaxis_sheetmetal,
  max_xaxis_sheetmetal,
  min_force_sheetmetal,
  max_force_sheetmetal,
  min_thickness_sheetmetal,
  max_thickness_sheetmetal,
  min_laser_sheetmetal,
  max_laser_sheetmetal,
  min_clamping_force,
  max_clamping_force,
  min_screw_diameter,
  max_screw_diameter,
  min_maximum_reach,
  max_maximum_reach,
  min_maximum_payload,
  max_maximum_payload,
}: {
  categoryApiName?: string;
  brands?: ValueAndLabel[];
  models?: ValueAndLabel[];
  localization_continent?: string[];
  localization_country?: ValueAndLabel[];
  application_type?: ValueAndLabel[];
  machine_type?: ValueLabelSlug[];
  year_range?: string;
  control_unit_brand?: string[];
  min_price?: number;
  max_price?: number;
  search?: string;
  order_by?: string;
  page?: number;
  locale: string;
  min_xaxis_movement?: number;
  max_xaxis_movement?: number;
  min_tablesize?: number;
  max_tablesize?: number;
  min_xaxis_sheetmetal?: number;
  max_xaxis_sheetmetal?: number;
  min_force_sheetmetal?: number;
  max_force_sheetmetal?: number;
  min_thickness_sheetmetal?: number;
  max_thickness_sheetmetal?: number;
  min_laser_sheetmetal?: number;
  max_laser_sheetmetal?: number;
  min_clamping_force?: number;
  max_clamping_force?: number;
  min_screw_diameter?: number;
  max_screw_diameter?: number;
  min_maximum_reach?: number;
  max_maximum_reach?: number;
  min_maximum_payload?: number;
  max_maximum_payload?: number;
}) {
  return [
    'products',
    undefinedToNull({
      categoryApiName,
      locale,
      brands,
      models,
      localization_continent,
      localization_country,
      application_type,
      machine_type,
      year_range,
      control_unit_brand,
      min_price,
      max_price,
      search,
      order_by,
      page,
      min_xaxis_movement,
      max_xaxis_movement,
      min_tablesize,
      max_tablesize,
      min_xaxis_sheetmetal,
      max_xaxis_sheetmetal,
      min_force_sheetmetal,
      max_force_sheetmetal,
      min_thickness_sheetmetal,
      max_thickness_sheetmetal,
      min_laser_sheetmetal,
      max_laser_sheetmetal,
      min_clamping_force,
      max_clamping_force,
      min_screw_diameter,
      max_screw_diameter,
      min_maximum_reach,
      max_maximum_reach,
      min_maximum_payload,
      max_maximum_payload,
    }),
  ];
}

function undefinedToNull(obj: any) {
  return assignWith({}, obj, (_, value) =>
    typeof value == 'undefined' || isEmpty(obj) ? null : value
  );
}
