/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import Select from 'react-select';

export type ValueAndLabel = { value: string; label: string };
export type ValueLabelSlug = ValueAndLabel & { slug: string };
export interface GroupedOptions {
  label: string;
  options: { value: string; label: string; slug: string }[];
}
export type AutocompleteProps = (
  | { isMulti: true; onChange?: (value: ValueAndLabel[]) => void }
  | { isMulti?: false; onChange?: (value: ValueAndLabel) => void }
) & {
  name?: string;
  options?: ValueAndLabel[] | GroupedOptions[];
  containerClasses?: string;
  label?: string;
  placeholder?: ReactNode;
  borderRadius?: string;
  onInputChange?: (value: string) => void;
  value?: ValueAndLabel | ValueAndLabel[];
  customNoOptionsMessage?: string;
};

export default function Autocomplete(props: AutocompleteProps) {
  const customStyles = {
    input: (provided: any) => ({
      ...provided,
      'input:focus': {
        boxShadow: 'none',
        borderRadiu: '2em',
      },
    }),
    control: (provider: any) => ({
      ...provider,
      borderRadiu: props.borderRadius ?? '0px',
      'control:focus': {
        borderColor: '#6b7280',
      },
    }),
  };

  return (
    <div className={props.containerClasses}>
      <label className='input-label' htmlFor={props.name}>
        {props.label}
      </label>

      <Select
        placeholder={props.placeholder}
        options={props.options}
        styles={customStyles}
        onChange={props.onChange as any}
        // getOptionLabel={(c: Country) => c.name}
        // getOptionValue={(c: Country) => c.alpha2}
        isMulti={props.isMulti}
        id={props.name}
        onInputChange={props.onInputChange as any}
        value={!props.value && props.isMulti ? [] : props.value}
        noOptionsMessage={
          () => props.customNoOptionsMessage ?? 'No options' // TODO: i18n
        }
      />
    </div>
  );
}
